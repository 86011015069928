import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../containers/layout'
import Page from '../components/page/page'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      _rawBody
      mainImage {
        asset {
          fluid(maxWidth:1900) {
            ...GatsbySanityImageFluid
          }
        }
      }
      pageSidebar {
        title
        links {
          _key
          title
          siteLink
        }
      }
      seoSettings {
        title
        description
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
    sanitySiteSettings {
      mainImagePages {
        asset {
          fluid(maxWidth:1900) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
const PagePage = ({data: {sanityPage: page, sanitySiteSettings: site}}) => (

  <Layout mainImage={page.mainImage && page.mainImage.asset ? page.mainImage : site.mainImagePages} title={page.title}>
    {page.seoSettings && page.seoSettings.title && page.seoSettings.description &&
        (<SEO title={page.seoSettings.title} description={page.seoSettings.description} openGraphImage={page.seoSettings.openGraphImage ? page.seoSettings.openGraphImage.asset.url : null} />
        )}
    <Page
      title={page.title}
      _rawBody={page._rawBody}
      sideBar={page.pageSidebar}
    />
  </Layout>
)

export default PagePage
