import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
// import Img from 'gatsby-image'
import Footer from '../Footer'
import PortableText from '../serializers/portableText'
import Container from '../container'
import SideBar from '../SideBar'

import styles from './page.module.css'

const Page = ({title, _rawBody, sideBar}) => {
  const data = useStaticQuery(graphql`
    {
      site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
        title
      }
      sanitySiteSettings {
        logos {
          _key
          link
          title
          logoImage {
            asset {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <article className={styles.root}>
        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>
          <div>

            <div className={styles.mainContent}>

              <div className={styles.innerMainContent} >
                {_rawBody && <PortableText blocks={_rawBody} />}
              </div>

              {sideBar && (<SideBar data={sideBar} />)}

            </div>

          </div>
        </Container>
      </article>

      <Footer topBorder />
    </>
  )
}
export default Page
