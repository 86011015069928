import React from 'react'
import Navigation from './Navigation'

import styles from './sidebar.module.css'

export default function sideBar ({data, barClass}) {
  // console.log({data})
  return (
    <div className={barClass === true ? styles.sidebarWrapper2 : styles.sidebarWrapper}>
      <Navigation nav={data} />
    </div>
  )
}
