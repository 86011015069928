import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import Logo from '../images/logo-square.svg'
import styles from './page/page.module.css'

export default function Footer ({topBorder}) {
  const data = useStaticQuery(graphql`
  {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
    }
    sanitySiteSettings {
      logos {
        _key
        link
        title
        logoImage {
          asset {
            url
          }
        }
      }
    }
      sanitySideBar(id: {eq: "a5a04730-4107-510d-8c16-a054f2aa2c31"}) {
        title
        links {
          _key
          title
          siteLink
        }
      }

  }
  `)
  // isLoggedIn ? { display:'block'} : {display : 'none'}
  return (

    <footer className={styles.footer} style={topBorder && {borderTop: '2px solid #e4e4e4'}} >
      <div className={styles.topfooterWrapper}>
        <span><Link to='/' aria-label='Logo'><Logo /></Link></span>
        <span className={styles.logosWrapper}>
          {data.sanitySiteSettings.logos.map(logo => (
            <a href={logo.link} key={logo._key} target='_blank' rel='noopener noreferrer' title={logo.title} aria-label='Logo linked to the home page'>
              <img src={logo.logoImage.asset.url} alt={logo.title} /> {logo.title}
            </a>
          ))}
        </span>
      </div>
      <div className={styles.footerWrapper}>
        <span> &copy; {new Date().getFullYear()} {data.site.title} All rights reserved. </span>
        <span> Site by <a href='https://www.variantstudios.com'>Variant Studios</a> </span>
      </div>
    </footer>
  )
}
